
import { Component, Vue } from "vue-property-decorator";
import DocumentCardShimmer from "@/components/DocumentCardShimmer.vue";
@Component({
  name: "DocumentsPastLeasingsShimmer",
  components: {
    DocumentCardShimmer,
  },
})
export default class DocumentsPastLeasingsShimmer extends Vue {}
